import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { routes } from '@routes';

import { CleanLayout } from '@layouts/clean';

import { ShareJobPage } from 'app/pages/Anonymous/ShareJob';
import { OpenInASOneJobPage } from 'app/pages/Anonymous/OpenInASOne';
import { AcceptInvitationPage } from 'app/pages/Anonymous/AcceptInvitationPage';
import { PaymentResult } from 'app/pages/Anonymous/PaymentResult';

import { UnauthenticatedRoutes } from '@wrappers/routes/UnauthenticatedRoutes';
import { AuthenticatedRoutes } from '@wrappers/routes/AuthenticatedRoutes';

import { Initialization } from './Initialization';
import { useInitContext } from '@providers/InitProvider';
import * as Sentry from '@sentry/react';
import { InternalErrorPage } from '@pages/500';
import { NoOrgsPage } from '@pages/User/NoOrgsPage';
import { AuthProvider } from '@providers/AuthProvider';
import { ModalProvider } from '@providers/ModalProvider';
import { WebsocketProvider } from '@providers/WebsocketProvider';
import { LandingPage } from '@pages/LandingPage';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Main = () => {
  const { isInitialized, isAuthenticated } = useInitContext();

  if (!isInitialized) {
    return <Initialization />;
  }

  return (
    <Router>
      <AuthProvider>
        <WebsocketProvider>
          <ModalProvider>
            <SentryRoutes>
              <Route path={routes.paymentSuccess.pattern} element={<PaymentResult isFailure={false} />} />
              <Route path={routes.paymentFailure.pattern} element={<PaymentResult isFailure={true} />} />

              <Route
                path={routes.shareJob.pattern}
                element={
                  <CleanLayout>
                    <Helmet>
                      <title>Shared Job - AutoScript</title>
                    </Helmet>
                    <ShareJobPage />
                  </CleanLayout>
                }
              />

              <Route path={routes.acceptInvitation.pattern} element={<AcceptInvitationPage />} />

              <Route path={routes.internalError.pattern} element={<InternalErrorPage />} />

              <Route
                path={routes.noOrgs.pattern}
                element={
                  <CleanLayout>
                    <Helmet>
                      <title>No Organizations - AutoScript</title>
                    </Helmet>
                    <NoOrgsPage />
                  </CleanLayout>
                }
              />

              <Route
                path={routes.openInASOne.pattern}
                element={
                  <CleanLayout>
                    <Helmet>
                      <title>Open AutoScriptOne - AutoScript</title>
                    </Helmet>
                    <OpenInASOneJobPage />
                  </CleanLayout>
                }
              />

              <Route
                path={routes.landing.pattern}
                element={
                  <>
                    <Helmet>
                      <title>AutoScript</title>
                    </Helmet>
                    <LandingPage />
                  </>
                }
              />

              <Route
                path="*"
                element={
                  <>
                    {!isAuthenticated ? <UnauthenticatedRoutes /> : null}
                    {isAuthenticated ? <AuthenticatedRoutes /> : null}
                  </>
                }
              />
            </SentryRoutes>
          </ModalProvider>
        </WebsocketProvider>
      </AuthProvider>
    </Router>
  );
};
