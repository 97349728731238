import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { routes } from '@routes';

import { getRepositoriesWithStatuses } from '@queries/organizations/getRepositoriesWithStatuses';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { useAuthContext } from '@providers/AuthProvider';
import { checkAssignedRepositories } from '@helpers/checkAssignedRepositories';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Button } from '@components/form-elements/buttons/Button';
import { Add as AddIcon } from '@components/icons/Add';
import { CustomTable } from '@components/Table';
import { PagePagination } from '@components/Pagination';
import { Popover } from '@components/Popover';

import { Filter } from './Filter';
import { TRepository } from 'app/types/entities/TRepository';
import { EPermission } from 'app/types/enums/EPermission';
import { TRepositoryStatus } from 'app/types/entities/TRepositoryStatus';

const StyledLink = styled(RouterLink)`
  font-style: normal;
  font-weight: 600;
  color: #858dbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

const Wrapper = styled.div`
  margin: 20px 0 0;

  table td p {
    margin: 16px 0;
  }
`;

const Content = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);
`;

const StyledButton = styled(Button)`
  width: 140px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
`;

export const RepositoriesListPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryPage = searchParams.get('page');
  const page = queryPage ? Number(queryPage) : 1;
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(25);
  const [repositories, setRepositories] = useState<{ count: number; data: TRepositoryStatus[] }>({
    count: 0,
    data: [],
  });
  const [createdFilter, setCreatedFilter] = useState<any>({
    from: undefined,
    to: undefined,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const setPage = (page: number) => {
    navigate(`?page=${page}`);
  };

  const { organization, me } = useAuthContext();

  const onCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleAddCase = () => {
    navigate(
      {
        pathname: routes.newRepository.make(organization?.slug ?? ''),
      },
      {
        state: {
          from: routes.repositoriesList.make(organization?.slug ?? ''),
        },
      },
    );
  };

  const reloadPage = () => {
    setPage(1);
    if (!organization?.id) {
      return;
    }
    setIsLoading(true);
    const assignedRepos = checkAssignedRepositories(me, organization.id) as unknown as TRepositoryStatus[];
    let result: any = {
      count: assignedRepos.length,
      items: assignedRepos,
    };

    getRepositoriesWithStatuses(organization.id, page, pageSize, search, createdFilter).then((res) => {
      if (res.count > 0) {
        result = res;
      }
      setRepositories(result);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    reloadPage();
  }, [organization, page, pageSize, search]);

  const FilterPopover = () => {
    return (
      <Popover
        styles="margin-top: 40px; margin-left: -100px;"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseFilter}
      >
        <Filter
          createdFilter={createdFilter}
          createdFilterOnChange={(value) => setCreatedFilter(value)}
          handleSubmit={reloadPage}
          handleClose={onCloseFilter}
        />
      </Popover>
    );
  };

  const tableHead = [
    {
      headComponent: () => <td>Case Title</td>,
      render: (data: TRepository) => (
        <p>
          <StyledLink
            to={{
              pathname: routes.repositoryDetails.make(organization?.slug ?? '', data.slug),
            }}
            state={{
              from: routes.repositoriesList.make(organization?.slug ?? ''),
            }}
          >
            {data.name}
          </StyledLink>
        </p>
      ),
    },
    {
      headComponent: () => <td>Created</td>,
      render: (data: TRepository) => <p>{getAmericanTime(data.createdAt)}</p>,
    },
    {
      headComponent: () => <td>Jobs (Processing / Ready For Editing / Complete)</td>,
      render: (data: TRepository) => (
        <p>
          {data.totalJobs} ({data.jobsProcessing} / {data.jobsReadyForTranscribing} / {data.jobsComplete})
        </p>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Cases List - AutoScript</title>
      </Helmet>
      <Header
        title="Cases"
        setSearch={setSearch}
        search={search}
        leftSideContent={[
          <div key={1}>
            <Restricted to={EPermission.editRepositories}>
              <StyledButton key="button" data-testid="header_case_button" size="small" onClick={handleAddCase}>
                <AddIcon height="12px" width="12px" />
                Create New Case
              </StyledButton>
            </Restricted>
          </div>,
        ]}
        onFilterClick={(e) => setAnchorEl(e.currentTarget)}
        filterCounter={createdFilter.from || createdFilter.to ? 1 : 0}
      />
      <Content>
        <LinearProgressLoader active={isLoading} />
        <Wrapper>
          <CustomTable data={repositories.data} head={tableHead} />
          <PagePagination totalItemsCount={repositories.count ?? 0} pageSize={pageSize} setPageSize={setPageSize} />
        </Wrapper>
      </Content>

      <FilterPopover />
    </>
  );
};
