import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import {
  GetJobSubscriptionsListDto,
  GetWorkspaceSubscriptionsListDto,
  JobSubscriptionsService,
  NotificationsService,
  SubscriptionsListDto,
  UpdateNotificationSettingsDto,
  WorkspaceSubscriptionsService,
} from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TState = {
  settings: any;
  subscriptions: SubscriptionsListDto[];
  jobSubscriptions: GetJobSubscriptionsListDto[];
  workspaceSubscriptions: GetWorkspaceSubscriptionsListDto[];
  updateSettings: (v: any) => void;
  deleteSubscription: (v: string) => void;
  unwatchJob: (v: string) => void;
  unwatchWorkspace: (v: string) => void;
  isLoading: boolean;
};

const PageContext = createContext<TState>({
  settings: {},
  subscriptions: [],
  jobSubscriptions: [],
  workspaceSubscriptions: [],
  updateSettings: () => {},
  deleteSubscription: () => {},
  unwatchJob: () => {},
  unwatchWorkspace: () => {},
  isLoading: false,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<any>({});
  const [subscriptions, setSubscriptions] = useState<SubscriptionsListDto[]>([]);
  const [jobSubscriptions, setJobSubscriptions] = useState<GetJobSubscriptionsListDto[]>([]);
  const [workspaceSubscriptions, setWorkspaceSubscriptions] = useState<GetWorkspaceSubscriptionsListDto[]>([]);

  const fetch = async () => {
    setIsLoading(true);
    const data = await call(NotificationsService.getNotificationSettings());
    setSettings(data);
    const subscriptions = await call(NotificationsService.getSubscriptions());
    setSubscriptions(subscriptions);
    const jobSubscriptions = await call(JobSubscriptionsService.getList());
    setJobSubscriptions(jobSubscriptions);
    const workspaceSubscriptions = await call(WorkspaceSubscriptionsService.getList());
    setWorkspaceSubscriptions(workspaceSubscriptions);
    setIsLoading(false);
  };

  const updateSettings = async (settings: UpdateNotificationSettingsDto) => {
    setIsLoading(true);
    const data = await call(NotificationsService.updateNotificationSettings({ requestBody: settings }));
    setSettings(data);
    setIsLoading(false);
  };

  const deleteSubscription = async (sid: string) => {
    setIsLoading(true);
    await call(NotificationsService.deleteSubscription({ sid }));
    fetch();
  };

  const unwatchJob = async (id: string) => {
    setIsLoading(true);
    await call(JobSubscriptionsService.delete({ jobId: id }));
    fetch();
  };

  const unwatchWorkspace = async (id: string) => {
    setIsLoading(true);
    await call(WorkspaceSubscriptionsService.delete({ workspaceId: id }));
    fetch();
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <PageContext.Provider
      value={{
        settings,
        updateSettings,
        isLoading,
        subscriptions,
        jobSubscriptions,
        workspaceSubscriptions,
        deleteSubscription,
        unwatchJob,
        unwatchWorkspace,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
