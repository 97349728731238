import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { Header } from '@components/Header';
import { DownloadAppIcon } from '@components/icons/DownloadApp/icon';
import { Button } from '@components/form-elements/buttons/Button';
import { getDownloadDesktopAppLink } from '@helpers/getDownloadDesktopAppLink';
import { UserService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const Content = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  video {
    border-radius: 10px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
    max-width: 850px;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #858dbd;
  margin: 0 0 20px;
`;

const Flex = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;
  padding: 20px 0;
  align-items: center;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: #858dbd;
`;

const StyledButton = styled(Button)`
  width: 170px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const DesktopAppHelpPage = () => {
  const { call } = useAPI();

  const onClick = () => {
    const link = getDownloadDesktopAppLink();
    call(UserService.downloadedClient());
    window && window.open(link, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Desktop App Help - AutoScript</title>
      </Helmet>
      <Header title="Desktop App Help" />

      <Content>
        <Flex>
          <StyledButton key="button" size="small" onClick={onClick}>
            <DownloadAppIcon stroke="#ffffff" />
            Download AutoScript
          </StyledButton>
          <Description>Enhanced editing features and audio player</Description>
        </Flex>

        <Title>AutoScript Desktop User Guide</Title>
        <video controls width="100%" poster="/video/Desktop.jpg">
          <source src="/video/Desktop.mp4" type="video/mp4" />
          <p>
            Your browser {"doesn't"} support HTML video. Here is a <a href="/video/Desktop.mp4">link to the video</a>{' '}
            instead.
          </p>
        </video>
      </Content>
    </>
  );
};
