import { useState } from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Textarea } from '@components/form-elements/Textarea';
import { Button } from '@components/form-elements/buttons/Button';
import { useAPI } from '@hooks/useAPI';
import { JobsService } from 'app/API';

const Wrapper = styled.div`
  display: flex;

  .textarea-wrapper {
    height: 50px;
  }

  .textarea {
    background: #ffffff;
    height: 50px;
  }

  form {
    display: block;
    width: 100%;
  }

  .form-line {
    display: flex;
    gap: 10px;
  }

  button {
    width: 130px;
    float: right;
  }
`;

const AvatarBlock = styled.div`
  flex: 0 0 auto;
  padding: 0 0 10px;
  display: flex;
  gap: 20px;
  align-items: center;
`;
const Avatar = styled.img`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
`;
const AvatarName = styled.div`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  text-transform: capitalize;
`;

type TProps = {
  workspaceId: string;
  jobId: string;
  setIsLoading: (value: boolean) => void;
  onSuccess?: () => void;
};

export const CommentForm = ({ workspaceId, jobId, onSuccess, setIsLoading }: TProps) => {
  const { call } = useAPI();
  const { me } = useAuthContext();
  const [comment, setComment] = useState('');

  const submit = async (e: { preventDefault: () => void }) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);
    await call(JobsService.commentJob({ wsid: workspaceId, id: jobId, requestBody: { comment } }));
    setIsLoading(false);
    setComment('');
    if (onSuccess) {
      onSuccess();
    }
  };

  const renderAvatar = () => {
    if (!me) {
      return '';
    }
    const avatarName = `${me?.name[0]}${me?.lastname[0]}`;
    return (
      <AvatarBlock>
        {me?.avatar ? <Avatar src={me.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
      </AvatarBlock>
    );
  };

  return (
    <Wrapper>
      <form onSubmit={submit}>
        <div className="form-line">
          {renderAvatar()}
          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label="Write your comment here"
            name="comment"
          />
        </div>
        <Button onClick={submit} disabled={!comment.length} className="button">
          Comment
        </Button>
      </form>
    </Wrapper>
  );
};
