import React from 'react';

import { Button } from '@components/form-elements/buttons/Button';

enum LoginStep {
  CHECK_DOMAIN = 'CHECK_DOMAIN',
  SSO_LOGIN = 'SSO_LOGIN',
  PASSWORD_LOGIN = 'PASSWORD_LOGIN',
}

type TProps = {
  disabled: boolean;
  loginStep: LoginStep;
  checkEmailDomain: () => void;
  emailDomainChecked: boolean;
  ssoRedirectUrl: string | null;
};

export const LoginButton = ({ disabled, loginStep, checkEmailDomain, ssoRedirectUrl }: TProps) => {
  if (loginStep === LoginStep.CHECK_DOMAIN) {
    return (
      <Button
        disabled={disabled}
        className="margin30"
        size="medium"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          checkEmailDomain();
        }}
      >
        Continue
      </Button>
    );
  }

  if (loginStep === LoginStep.SSO_LOGIN) {
    return (
      <Button
        disabled={disabled}
        className="margin30"
        size="medium"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          window.location.href = ssoRedirectUrl ?? '/dashboard';
        }}
      >
        Login via company login
      </Button>
    );
  }

  return (
    <Button disabled={disabled} type="submit" className="margin30" size="medium">
      Sign In
    </Button>
  );
};
