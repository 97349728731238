import { Switch } from '@components/form-elements/Switch';
import { Dots } from '@components/icons/Dots';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import toast from 'react-hot-toast';
import { SwitchWrapper, Wrapper } from './styles';
import { TProps } from './types';

export const AutoTopUpCard = ({ className = '', billingAccount, paymentMethods, reloadData }: TProps) => {
  const { openModal } = useModalContext();

  return (
    <Wrapper className={className}>
      <div className="content flex items-center">
        <h2 className="title mr-3">Auto Top Up</h2>
        <SwitchWrapper>
          <Switch checked={billingAccount.autoTopUpSettings?.enabled || false} onChange={() => {}} disabled={true} />
        </SwitchWrapper>
        <div
          className="dots-button"
          onClick={() => {
            if (!paymentMethods.default) {
              return toast.error('Please set up default payment method');
            }
            openModal(ModalNamesEnum.AutoTopUpSettings, { billingAccount, paymentMethods, reloadData });
          }}
        >
          <Dots />
        </div>
      </div>

      <div className="info">
        <div className="rate">${(billingAccount.autoTopUpSettings?.rechargeAmount || 0) / 100}</div>
      </div>
      <div className="description">
        When the balance falls below ${(billingAccount.autoTopUpSettings?.lowLimitAmount || 0) / 100}
      </div>
    </Wrapper>
  );
};
