import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import { WorkspacesService, WorkspaceSubscriptionsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { TRole } from 'app/types/entities/TRole';
import { EPermission } from 'app/types/enums/EPermission';
import { TWorkspace } from 'app/types/entities/TWorkspace';

import { Header } from '@components/Header';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { DisclosureSection } from '@components/DisclosureSection';
import { TeamCards } from '@components/todo/TeamCards';
import { Add as AddIcon } from '@components/icons/Add';

import { WorkspaceDetailsForm } from './components/Form/form';

import EyeClosedIcon from './icons/eye-closed.svg';
import EyeIcon from './icons/eye.svg';

import { WatchIconButton } from './styles/WatchIconButton';
import { StyledButton } from './styles/StyledButton';
import { Wrapper } from './styles/Wrapper';

export const WorkspaceDetailsPage = () => {
  const { call } = useAPI();
  const { orgSlug, wsSlug } = useParams<{ orgSlug: string; wsSlug: string }>();

  const { organization, me } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [teams, setTeams] = useState<TRole[]>([]);
  const [workspace, setWorkspace] = useState<TWorkspace>();
  const [teamsDisclosureOpen, setTeamsDisclosureOpen] = useState<boolean>(true);
  const { openModal, closeModal } = useModalContext();

  const loadWorkspaceAndTeams = async () => {
    setIsLoading(true);
    const teams = await call(WorkspacesService.getTeams({ orgSlug: orgSlug ?? '', wsSlug: wsSlug ?? '' }));
    setTeams(teams.result);
    const workspace = await call(
      WorkspacesService.getWorkspaceBySlugs({ orgSlug: orgSlug ?? '', wsSlug: wsSlug ?? '' }),
    );
    setWorkspace(workspace);
    setIsLoading(false);
  };

  useEffect(() => {
    loadWorkspaceAndTeams();
  }, []);

  const handleRemoval = (teamId: string) => {
    call(
      WorkspacesService.updateWorkspaceByRemovingTeam({ orgSlug: orgSlug ?? '', wsSlug: wsSlug ?? '', teamId }),
    ).then(loadWorkspaceAndTeams);
  };

  const handleAddTeam = () => {
    openModal(ModalNamesEnum.AddTeamToWorkspaceModal, {
      orgSlug,
      workspaceSlug: wsSlug,
      onSuccess: (selectedTeams: string[]) => {
        closeModal();
        call(
          WorkspacesService.updateWorkspaceByAddingTeam({
            orgSlug: orgSlug ?? '',
            wsSlug: wsSlug ?? '',
            requestBody: {
              teamIds: selectedTeams,
            },
          }),
        ).then(loadWorkspaceAndTeams);
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const headerLeftSideContent = [
    <StyledButton key="button" size="small" onClick={handleAddTeam}>
      <AddIcon height="12px" width="12px" />
      Add team
    </StyledButton>,
  ];

  const renderWatchButton = () => {
    const showWatchButton = !!workspace;
    if (!showWatchButton) {
      return null;
    }
    if (workspace?.watchers?.includes(me.id)) {
      return (
        <WatchIconButton
          title="Unwatch this workspace"
          onClick={async () => {
            await call(WorkspaceSubscriptionsService.delete({ workspaceId: workspace.id }));
            loadWorkspaceAndTeams();
          }}
        >
          <EyeClosedIcon style={{ marginRight: '-5px', height: '28px', width: '28px' }} />
          Unwatch
        </WatchIconButton>
      );
    }
    return (
      <WatchIconButton
        title="Watch this workspace"
        onClick={async () => {
          await call(WorkspaceSubscriptionsService.create({ workspaceId: workspace.id }));
          loadWorkspaceAndTeams();
        }}
      >
        <EyeIcon style={{ marginRight: '-5px', height: '28px', width: '28px' }} />
        Watch
      </WatchIconButton>
    );
  };

  const teamsTitle = 'Teams' + (teams.length > 0 ? ` (${teams.length})` : '');

  return (
    <>
      <Helmet>
        <title>{workspace?.name || 'Workspace'} - AutoScript</title>
      </Helmet>
      <Header title={workspace?.name} leftSideContent={headerLeftSideContent} />

      {workspace ? (
        <Wrapper>
          <div className="head-buttons">
            {!isLoading && organization?.permissions?.manageOrganization ? renderWatchButton() : null}
          </div>
          {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
          <WorkspaceDetailsForm workspace={workspace} isSubmitting={isLoading} setIsSubmitting={setIsLoading} />

          <div>
            <Restricted to={EPermission.manageMembers}>
              <DisclosureSection
                title={teamsTitle}
                open={teamsDisclosureOpen}
                setOpenState={setTeamsDisclosureOpen}
                loading={false}
              >
                <TeamCards teams={teams} handleRemoval={handleRemoval} addTeam={handleAddTeam} />
              </DisclosureSection>
            </Restricted>
          </div>
        </Wrapper>
      ) : null}
    </>
  );
};
