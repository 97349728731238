export const StarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4L14.417 8.67332L19.6085 9.52786L15.9107 13.2707L16.7023 18.4721L12 16.112L7.29772 18.4721L8.08926 13.2707L4.39155 9.52786L9.58303 8.67332L12 4Z"
      stroke="#858DBD"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
