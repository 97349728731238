import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { Spinner } from '@components/spinners/Spinner';
import { Block } from '@components/Block';
import { Input } from '@components/form-elements/Input';

import { PaymentIntentBox } from '../../modals/PaymentIntentBox';
import { Buttons } from './components/Buttons';
import { FormGenericErrors } from './components/FormGenericErrors';
import { NameUploadBlock } from '@pages/User/NewJobPage/components/Content/blocks/NameUploadBlock';
import { NotesBlock } from '@pages/User/NewJobPage/components/Content/blocks/NotesBlock';
import { RepositoryBlock } from '@pages/User/NewJobPage/components/Content/blocks/RepositoryBlock';
import { TagsBlock } from '@pages/User/NewJobPage/components/Content/blocks/TagsBlock';
import { DeadlineBlock } from '@pages/User/NewJobPage/components/Content/blocks/DeadlineBlock';
import { AssigneeBlock } from '@pages/User/NewJobPage/components/Content/blocks/AssigneeBlock';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { usePageContext } from '@pages/User/NewJobPage/providers/PageProvider';
import { TJob } from 'app/types/entities/TJob';
import { EPermission } from 'app/types/enums/EPermission';
import { jobIsScheduled } from '@helpers/jobStatus/jobIsScheduled';
import { useAPI } from '@hooks/useAPI';
import { JobsService, MediaService, PayService } from 'app/API';
import { sanitizeFileName } from '@helpers/sanitizeFileName';
import { LayoutBlock } from '@pages/User/NewJobPage/components/Content/blocks/LayoutBlock';
import sanitize from 'sanitize-filename';
import { Error } from '@components/Error';
import { EErrorMessages } from '@constants/errorMessages';
import toast from 'react-hot-toast';

function hasSpecialCharacters(input: string) {
  return /[^a-zA-Z0-9()_.\-\s]/.test(input);
}

const SmallWhiteInput = styled(Input)`
  input {
    background: #ffffff;
    padding: 0 10px;
    height: 30px;
  }
`;

const FormWrapper = styled('div')`
  padding: 30px 30px 0;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  .uploadDuration {
    color: #858dbd;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .uploadDescription {
    color: #575757;
  }

  .multipleFilesHandling {
    .description {
      padding: 10px 0 0;
      color: #878787;
    }
  }

  .repositoryBlockDescription {
    padding: 10px 0 0;
    color: #878787;
  }

  ul {
    margin: 0;
    color: #878787;
    padding: 0 0 0 18px;
  }

  .errorMessage {
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    color: #ff2f2f;
    height: 12px;
  }
`;

const StyledForm = styled('form')`
  //max-width: 720px;
  flex: 1 1 auto;
  position: relative;
  padding: 0 0 100px;
`;

export const Content = () => {
  const { call } = useAPI();
  const { organization, workspace } = useAuthContext();
  const { openModal, closeModal } = useModalContext();
  const navigate = useNavigate();

  const {
    jobId,
    isLoading,
    setIsLoading,
    form,
    setForm,
    newFiles,
    setNewFiles,
    filesCount,
    setFilesCount,
    allFiles,
    setAllFiles,
  } = usePageContext();

  const [paymentIntent, setPaymentIntent] = useState<{ url: string; id: string }>({
    url: '',
    id: '',
  });
  const [errorJobNameMessage, setErrorJobNameMessage] = useState('');
  // const [showErrorMessage, setShowErrorMessage] = useState(false);
  const filesCountRef = useRef(filesCount);

  useEffect(() => {
    filesCountRef.current = filesCount;
  });

  useEffect(() => {
    setErrorJobNameMessage('');

    if ((form?.item?.name || '').endsWith(' ')) {
      setErrorJobNameMessage(EErrorMessages.JOB_NAME_ENDS_WITH_SPACE);
      return;
    }

    if (sanitize(form?.item?.name || '') !== (form?.item?.name || '')) {
      setErrorJobNameMessage(EErrorMessages.WRONG_JOB_NAME_SYMBOLS);
      return;
    }
    if (hasSpecialCharacters(form?.item?.name || '')) {
      setErrorJobNameMessage(EErrorMessages.WRONG_JOB_NAME_SYMBOLS);
      return;
    }

    if ((form?.item?.name || '').length > 255) {
      setErrorJobNameMessage(EErrorMessages.JOB_NAME_IS_TOO_LONG);
    }
  }, [form?.item?.name]);

  const saveFilesOrder = () => {
    allFiles.forEach((item) => {
      if (jobId && item.meta.Id && !item.file?.repositoryId) {
        call(MediaService.updateMedia({ jobId, id: item.meta.Id, requestBody: { weight: item.weight ?? 0 } }));
      }
    });
  };

  const saveDraft = async () => {
    if (!workspace) {
      return;
    }
    setIsLoading(true);
    saveFilesOrder();
    await call(
      JobsService.createOrEditWsJob({
        wsid: workspace?.id,
        requestBody: { ...(form.item ?? ({} as TJob)), status: 'JOB_STATUS_DRAFT' },
      }),
    );
    setIsLoading(false);
  };

  const onSubmit = async (evt: Event) => {
    evt.preventDefault();
    await saveDraft();
    setIsLoading(true);
    const isErrorsInFiles = allFiles.some(
      (item) =>
        item.meta.status === 'error_upload' ||
        item.meta.status === 'error_analyzing' ||
        item.meta.status === 'rejected_file_type' ||
        item.meta.status === 'error_file_size',
    );
    if (!allFiles.length || isErrorsInFiles) {
      setIsLoading(false);
      return;
    }
    saveFilesOrder();
    openModal(ModalNamesEnum.PriceModal, {
      onCancel: onModalCancel,
      isLoading,
      setPaymentIntent,
      paymentIntent,
      onSuccess: handleTranscribe,
      jobId,
    });
  };

  const onModalCancel = () => {
    closeModal();
    setIsLoading(false);
  };

  async function handleTranscribe(model?: string) {
    setIsLoading(true);
    closeModal();
    await call(PayService.pay({ id: jobId, requestBody: { model: model ?? 'model-1' } }), {
      onError: (message) => {
        toast.error(message);
        setIsLoading(false);
      },
      onSuccess: () => {
        navigate(routes.jobsList.make(organization?.slug ?? '', workspace?.slug ?? ''));
        setIsLoading(false);
      },
    });
  }

  const updateNewFile = async (file: TFileWithMeta) => {
    const index = newFiles.findIndex((f) => f.meta.name === file.meta.name);
    if (index === -1) {
      setFilesCount(filesCountRef.current + 1);
      await setNewFiles([...newFiles, file]);
      return;
    }
    setNewFiles(((files: TFileWithMeta[]) => [
      ...files.map((prevFile) => {
        if (prevFile.meta.name === file.meta.name) {
          return file;
        }
        return prevFile;
      }),
    ]) as any);
  };

  const handleChangeStatus = useCallback(
    async (file: TFileWithMeta, status: string) => {
      if (status === 'ready') {
        file.upload();
        await updateNewFile(file);
        return;
      }

      if (
        status === 'error_upload' ||
        status === 'error_analyzing' ||
        status === 'rejected_file_type' ||
        status === 'error_file_size'
      ) {
        return;
      }
      if (status === 'removed') {
        const removalFilter = (f: TFileWithMeta) => f?.meta.name !== file?.meta.name;
        setNewFiles(newFiles.filter(removalFilter));
        setAllFiles(allFiles.filter(removalFilter));
        return;
      }

      if (status === 'getting_upload_params') {
        return;
      }

      if (status === 'preparing' || status === 'started') {
        updateNewFile(file);
        return;
      }

      if (status === 'uploading') {
        updateNewFile(file);
        return;
      }

      if (status === 'done') {
        // handleChangeStatus(file, 'ready');
        setFilesCount(filesCountRef.current - 1);

        try {
          (async () => {
            const weight = allFiles.findIndex((f) => f?.meta?.name === file?.meta.name);
            const fileName = sanitizeFileName(file.meta.name);
            const response = await call(
              MediaService.createMedia({
                requestBody: {
                  jobId,
                  fileName,
                  fileSize: file.file.size?.toString() || '0',
                  weight,
                  workspaceId: workspace?.id ?? '',
                },
              }),
            );

            if (!response || !response.id) {
              file.meta.status = 'error_upload';
              updateNewFile(file);
              return;
            }

            file.meta.duration = 0;
            file.meta.status = 'pre_calculating_duration';
            file.meta.Id = response.id;
            updateNewFile(file);
            // pollAnalysisResult(file);
          })();
        } catch (e) {
          Sentry.captureMessage(`Error while uploading file for job ${jobId}`, { extra: { file, e, jobId } });
          Sentry.captureException(e);
          console.error(e);
        }
      }
    },
    [allFiles, filesCountRef.current],
  );

  const onNewFiles = useCallback(
    async (files: TFileWithMeta[]) => {
      setFilesCount(filesCountRef.current + files.length);
      await setNewFiles([...newFiles, ...files]);
    },
    [allFiles, filesCountRef.current],
  );

  const handleInputChange = async (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    setForm({
      ...form,
      item: {
        ...form.item,
        [name]: value,
      } as TJob,
    });
  };

  const addToNewFilesWithReplace = (file: TFileWithMeta) => {
    const index = newFiles.findIndex((f) => f.meta.name === file.meta.name);
    const filesList = index === -1 ? [...newFiles.filter((f) => f.meta.name !== file.meta.name), file] : [...newFiles];
    const newIndex = filesList.findIndex((f) => f.meta.name === file.meta.name);
    filesList[newIndex] = file;

    setNewFiles(filesList);
  };

  const pollAnalysisResult = (file: TFileWithMeta) => {
    let analysisChecksCount = 0;
    const interval = setInterval(async () => {
      const analysisResponse = await call(MediaService.getAnalysisStatus({ id: file.meta.Id || file.meta.id }));

      if (analysisResponse?.returnvalue) {
        file.meta.duration = analysisResponse.returnvalue.duration;
        file.meta.status = file.meta.duration ? 'done' : 'error_analyzing';
        addToNewFilesWithReplace(file);
        clearInterval(interval);
        return;
      }

      analysisChecksCount++;
      if (analysisChecksCount >= 60 || analysisResponse.failedReason) {
        file.meta.duration = 0;
        file.meta.status = 'error_upload';
        addToNewFilesWithReplace(file);
        clearInterval(interval);
        return;
      }
    }, 5000);
  };

  useEffect(() => {
    newFiles.forEach((file) => {
      if (!file.meta.duration && (file.meta.status === 'done' || file.meta.status === 'pre_calculating_duration')) {
        file.meta.status = 'calculating_duration';
        addToNewFilesWithReplace(file);
        pollAnalysisResult(file);
      }
    });
  }, [newFiles]);

  return (
    <>
      {isLoading ? <Spinner overlay={true} /> : null}

      <FormWrapper>
        <FormGenericErrors />

        <Block title="Provide a unique title for your job">
          <SmallWhiteInput
            onChange={handleInputChange}
            name="name"
            label="Title"
            value={form.item?.name || ''}
            required={true}
          />
          {errorJobNameMessage ? <Error error={errorJobNameMessage} /> : <></>}
        </Block>

        {form.item?.status && !form.item.asOneLocked && jobIsScheduled(form.item.status) ? null : (
          <NameUploadBlock
            newFiles={newFiles}
            setNewFiles={setNewFiles}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
            handleChangeStatus={handleChangeStatus}
            onNewFiles={onNewFiles}
          />
        )}

        <LayoutBlock />

        <Restricted to={EPermission.viewRepositories}>
          <RepositoryBlock />
        </Restricted>

        <NotesBlock />
        <TagsBlock />
        <DeadlineBlock />
        <AssigneeBlock />
      </FormWrapper>

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <StyledForm className="form" method="post" onSubmit={onSubmit}>
        <Buttons
          saveFilesOrder={saveFilesOrder}
          filesLength={allFiles.length}
          errorJobNameMessage={errorJobNameMessage}
        />
      </StyledForm>
      <PaymentIntentBox paymentIntent={paymentIntent} />
    </>
  );
};
