import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { useInitContext } from '@providers/InitProvider';
// import { useAPI } from '@hooks/useAPI';
import { Initialization } from '@wrappers/Main/Initialization';

import { Wrapper } from './styles/Wrapper';
import logo from './images/logo.png';
import trusted from './images/trusted.png';
import empowering from './images/empowering.jpg';
import footerLogo from './images/footer-logo.png';

export const LandingPage = () => {
  // const { call } = useAPI();
  const navigate = useNavigate();

  const salesLink = 'mailto:sales@voicescript.ai';
  const openDashboard: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    navigate('/dashboard');
  };
  const goToLogin: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    navigate(routes.signin.make());
  };
  const goToRegister: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    navigate(routes.signup.make());
  };

  const { isAuthenticated, isInitialized } = useInitContext();

  if (!isInitialized) {
    return <Initialization />;
  }

  return (
    <Wrapper>
      <header>
        <div className="left-side">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="menu"></div>
        </div>

        <div className="right-side">
          {!isAuthenticated ? (
            <button className="link-button" onClick={goToLogin}>
              Log In
            </button>
          ) : null}
          <a className="white-button" href={salesLink}>
            Contact Sales
          </a>
          {!isAuthenticated ? (
            <button className="primary-button" onClick={goToRegister}>
              Get Started
            </button>
          ) : (
            <button className="primary-button" onClick={openDashboard}>
              Dashboard
            </button>
          )}
        </div>
      </header>

      <main>
        <section className="top">
          <h1>Revolutionize Transcript Production With AutoScript by VoiceScript</h1>
          <h3>
            Boost Productivity and Profitability with the Industry’s Leading Court Reporting and Transcription Tools
          </h3>
          {!isAuthenticated ? (
            <button className="primary-button" onClick={goToRegister}>
              Register & Get Started for Free
            </button>
          ) : (
            <button className="primary-button" onClick={openDashboard}>
              Open Dashboard
            </button>
          )}
        </section>

        <section className="empowering">
          <div className="empowering-title">
            Empowering the <span>Next Generation</span> of Court Reporting
          </div>
          <img src={empowering} alt="" />
        </section>

        <section className="blocks">
          <div className="block">
            <div className="block-title">AutoScript Speech-to-Text</div>
            <div className="block-content">
              Generate accurate draft transcripts with the industry’s most accurate automated speech recognition
              platform
            </div>
          </div>
          <div className="block">
            <div className="block-title">Powerful Transcript Editor</div>
            <div className="block-content">
              Edit and proof transcripts effortlessly with our free, easy to use AutoScript Desktop Editor or your
              favorite CAT software
            </div>
          </div>
          <div className="block">
            <div className="block-title">AutoScriptOne – Realtime Reporter</div>
            <div className="block-content">
              Capture, edit and produce client-ready roughs in real-time during live proceedings
            </div>
          </div>
          <div className="block">
            <div className="block-title">AutoScript Hub</div>
            <div className="block-content">
              Efficiently collaborate with colleagues or manage small to large agency teams at no additional cost
            </div>
          </div>
        </section>

        <section className="empowering-title">Everything You Need to Do the Best</section>
        <section className="empowering-blocks">
          <div className="empowering-block empowering-block-1">
            <div className="block-title">Built for Legal Professionals</div>
            <div className="block-content">
              Our tools are specifically designed to meet the unique needs of the legal industry
            </div>
          </div>
          <div className="empowering-block empowering-block-2">
            <div className="block-title">Enhanced Productivity</div>
            <div className="block-content">Automate and expedite transcription tasks to save valuable time</div>
          </div>
          <div className="empowering-block empowering-block-3">
            <div className="block-title">High Accuracy</div>
            <div className="block-content">Leverage advanced speech recognition optimized for legal terminology</div>
          </div>
          <div className="empowering-block empowering-block-4">
            <div className="block-title">Secure and Confidential</div>
            <div className="block-content">Your data is secure with top-tier encryption and legal compliance</div>
          </div>
        </section>
      </main>

      <section className="trusted">
        <div className="trusted-title">Trusted by Companies and Teams</div>
        <img src={trusted} alt="" />
      </section>

      <footer>
        <div className="first-line">
          <div className="left-side">
            <div className="logo">
              <img src={footerLogo} alt="" />
            </div>
            <div className="menu-columns">
              <div className="menu">
                <div className="title">Social</div>
                <a href="https://www.facebook.com/voicescriptinc">Facebook</a>
                <a href="https://www.linkedin.com/company/voicescript/">LinkedIn</a>
                <a href="https://www.youtube.com/@voicescript8018">YouTube</a>
              </div>
              <div className="menu">
                <div className="title">More</div>
                <a href="https://voicescript.ai/">VoiceScript.ai</a>
                <a href="https://thecourtreportingacademy.com/">TCRA</a>
              </div>
              <div className="contacts">
                <div className="title">Contacts</div>
                <div>
                  <a href="mailto:contact@voicescript.ai">contact@voicescript.ai</a>
                </div>
                <div>
                  <a href="tel:+17726215205">(772) 621-5205</a>
                </div>
                <div>Cocoa, Florida 32926, US</div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyrights">
          <div className="menu">
            <RouterLink to="https://voicescript.ai/terms-of-service/">Terms of service</RouterLink>&nbsp;|&nbsp;
            <RouterLink to="https://voicescript.ai/privacy-policy/">Privacy policy</RouterLink>
          </div>
          <div className="copyright">© {new Date().getFullYear()} VoiceScript, Inc. All rights reserved</div>
        </div>
      </footer>
    </Wrapper>
  );
};
