import styled from 'styled-components';

import emp1bg from '../images/empowering/p1.jpg';
import emp2bg from '../images/empowering/p2.jpg';
import emp3bg from '../images/empowering/p3.jpg';
import emp4bg from '../images/empowering/p4.jpg';

export const Wrapper = styled.div`
  max-width: 1440px;
  min-width: 960px;
  padding: 40px 70px 70px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;

  header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    gap: 20px;
    align-items: center;

    .left-side {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      .logo {
        width: 150px;
        flex: 0 0 auto;
        img {
          width: 100%;
        }
      }
    }
    .right-side {
      display: flex;
      gap: 20px;
      align-items: center;
    }
  }

  .primary-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
    border: none;
    color: #fff;
    white-space: nowrap;
    &:hover {
      background: linear-gradient(90deg, #adc5e8 0%, #40608f 100%);
    }
  }

  .white-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    color: #858dbd;
    white-space: nowrap;
    border: 1px solid #d5def2;
    background: none;
    &:hover {
      border: 1px solid #2f5d87;
      color: #2f5d87;
    }
  }

  .link-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 5px;
    padding: 12px 16px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    background: none;
    border: none;
    color: #40608f;
    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    color: #001a40;
    font-size: 70px;
    font-weight: 600;
    line-height: 91px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 80px 0 40px;
  }

  h3 {
    color: #858dbd;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0 0 40px;
  }

  section.top {
    padding: 0 0 140px;
    text-align: center;
    button {
      width: 280px;
      margin: 0 auto;
    }
  }

  section.blocks {
    display: flex;
    gap: 20px;
    padding: 0 0 140px;
    .block {
      background: #ffffff;
      padding: 20px;
      border-radius: 20px;
      .block-title {
        padding: 0 0 10px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #001a40;
      }
      .block-content {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #858dbd;
      }

      &:hover {
        background: #001a40;
        .block-title,
        .block-content {
          color: #f6f8f9;
        }
      }
    }
  }

  section.solutions {
    padding: 0 0 140px;
    img {
      width: 100%;
    }
  }

  section.trusted {
    padding: 0 0 140px;
    .trusted-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #b4b9d9;
      padding: 0 0 40px;
    }
    img {
      width: 100%;
    }
  }

  section.empowering {
    padding: 0 0 40px;
    .empowering-title {
      font-size: 40px;
      font-weight: 600;
      line-height: 52px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #001a40;
      padding: 0 0 60px;
      span {
        color: #7cb1fe;
      }
    }
    img {
      width: 100%;
    }
  }

  section.empowering-title {
    color: #001a40;
    font-size: 50px;
    font-weight: 600;
    line-height: 65px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 0 0 60px;
    width: 500px;
  }

  section.empowering-blocks {
    display: flex;
    gap: 20px;
    padding: 0 0 150px;
    .empowering-block-1 {
      background: url(${emp1bg}) no-repeat scroll left top transparent;
    }
    .empowering-block-2 {
      background: url(${emp2bg}) no-repeat scroll left top transparent;
    }
    .empowering-block-3 {
      background: url(${emp3bg}) no-repeat scroll left top transparent;
    }
    .empowering-block-4 {
      background: url(${emp4bg}) no-repeat scroll left top transparent;
    }

    .empowering-block {
      position: relative;
      padding: 20px;
      border-radius: 20px;
      flex: 0 0 auto;
      width: 310px;
      height: 500px;
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
      }
      .block-title {
        padding: 0 0 18px;
        font-size: 26px;
        font-weight: 600;
        line-height: 33.8px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #001a40;
      }
      .block-content {
        width: 290px;
        height: 110px;
        margin: 0 0 0 -10px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        font-size: 18px;
        font-weight: 500;
        line-height: 23.4px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #001a40;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 15px;
      }
    }
  }

  footer {
    .first-line {
      padding: 0 0 60px;
      display: flex;
      .left-side {
        .logo {
          flex: 0 0 auto;
          width: 280px;
          padding: 0 0 60px;
          img {
            width: 100%;
          }
        }
      }
      .menu-columns {
        color: #40608f;
        text-align: left;
        display: flex;
        gap: 120px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        .menu,
        .contacts {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .title {
          color: #40608f;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          padding: 0 0 10px;
        }
        a {
          color: #40608f;
          display: block;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .copyrights {
      .menu {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #858dbd;
        padding: 0 0 20px;

        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .copyright {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #858dbd;
      }
    }
  }
`;
