import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { assignLicense } from '@queries/subscriptions/assignLicense';
import { getLicenses } from '@queries/subscriptions/getLicenses';
import { getSubscriptionDetails, TGetSubscriptionDetailsResponse } from '@queries/subscriptions/getSubscriptionDetails';

import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { routes } from '@routes';

import { Header } from '@components/Header';
import { Button } from '@components/form-elements/buttons/Button';
import { CustomTable } from '@components/Table';
import { TrashBin } from '@components/icons/TrashBin';
import { Add as AddIcon } from '@components/icons/Add';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { TLicense } from 'app/types/entities/TLicense';
import { TUser } from 'app/types/entities/TUser';
import { useAPI } from '@hooks/useAPI';
import { BillingAccountService } from 'app/API';

const CellContent = styled.div`
  margin: 0;
  padding: 15px 0;

  svg {
    cursor: pointer;
    padding: 5px;
    height: 30px;
    width: 30px;
  }

  svg.add {
    stroke: #40608f;
  }

  svg.delete {
    path {
      fill: #40608f;
    }
  }
`;

const CellContentFlex = styled(CellContent)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 90px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
  margin-right: 12px;
  justify-content: center;
`;

const MainContent = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }

  .details {
    padding: 15px 0 20px;
    display: flex;
    gap: 25px;
    align-items: center;

    .left {
      color: #b4b9d9;

      .name {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-transform: capitalize;
      }

      .price {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
      }
    }

    .divider {
      border-right: 1px solid #d5def2;
      height: 40px;
    }

    .count {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      color: #858dbd;
      border: 1px solid #d5def2;
      border-radius: 10px;
      padding: 0 16px;
      height: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .bold {
        font-weight: bold;
      }
    }
  }
`;

const AvatarBlock = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  .avatar {
    width: 30px;
    height: 30px;
    background: #cadcf8;
    border-radius: 5px;
  }

  .avatar-name {
    width: 30px;
    height: 30px;
    background: #cadcf8;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-transform: capitalize;
  }
`;

type Assignee = {
  id: string;
  membershipId: string;
  name: string;
  lastname: string;
  email: string;
};

export const ASOneSubscriptionDetails = () => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();
  const { organization, me } = useAuthContext();
  const { orgSlug, aOneId } = useParams<{ orgSlug: string; aOneId: string }>();
  const [search, setSearch] = useState('');
  const [plans, setPlans] = useState<TSubscriptionProduct[]>([]);
  const [subscription, setSubscription] = useState<TGetSubscriptionDetailsResponse | null>(null);
  const [licenses, setLicenses] = useState<TLicense[]>([]);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const org = me.organizations.find((o) => o.slug === orgSlug);
  if (!org || !org.billingAccount) {
    return <></>;
  }

  const baId = org.billingAccount.id;

  useEffect(() => {
    setIsLoading(true);
    getLicenses(baId, aOneId ?? '').then((res) => {
      setLicenses(res);
      setIsLoading(false);
    });
  }, [baId, aOneId, reloadPageTrigger]);

  const onAssignLicense = async (assignee: Assignee, id: string | null) => {
    setIsLoading(true);
    closeModal();
    let licenseId: string;
    if (id) {
      licenseId = id;
    } else {
      const license = subscription?.licenses.find((license) => license.assigneeId === null);
      licenseId = license?.id ?? '';
    }
    const result = await assignLicense(baId, {
      subscriptionId: aOneId ?? '',
      licenseId,
      assigneeId: assignee.membershipId,
    });
    if (result.error) {
      openModal(ModalNamesEnum.GenericErrorModal, {
        message: result.error,
      });
    }
    setReloadPageTrigger(!reloadPageTrigger);
  };

  const unassignedCount = subscription?.licenses.filter((lic) => lic.assigneeId === null).length;
  const assignedCount = subscription?.licenses.filter((lic) => lic.assigneeId !== null).length;

  const onAddUserClick = (id: string | null) => {
    openModal(ModalNamesEnum.AddUserLicenseModal, {
      baId,
      subId: aOneId,
      onSuccess: (assignee: Assignee) => onAssignLicense(assignee, id),
    });
  };

  const removeAssignee = async (licenseId: string) => {
    setIsLoading(true);
    const result = await assignLicense(baId, {
      subscriptionId: aOneId ?? '',
      licenseId,
      assigneeId: null,
    });
    if (result.error) {
      openModal(ModalNamesEnum.GenericErrorModal, {
        message: result.error,
      });
    }
    setReloadPageTrigger(!reloadPageTrigger);
  };

  const headerLeftSideContent = [];
  if (organization?.permissions?.manageLicenses) {
    headerLeftSideContent.push(
      <div className="flex flex-row">
        <StyledButton
          key="button"
          size="small"
          disabled={(unassignedCount ?? 0) < 1}
          onClick={() => onAddUserClick(null)}
        >
          + Add User
        </StyledButton>
      </div>,
    );
  }

  useEffect(() => {
    (async function () {
      if (!me.id) {
        return;
      }
      setIsLoading(true);
      const plans = await call(BillingAccountService.getAsOnePlans());
      setPlans(plans);
      const subscription = await getSubscriptionDetails(baId, aOneId ?? '');
      setSubscription(subscription);
    })();
  }, [me, reloadPageTrigger, baId, aOneId]);

  const header = (
    <>
      <Helmet>
        <title>AutoScriptOne Subscription Details - AutoScript</title>
      </Helmet>
      <Header
        title={'Subscription Details'}
        setSearch={setSearch}
        search={search}
        leftSideContent={headerLeftSideContent}
        backRoute={routes.billingAccount.make(organization?.slug ?? '')}
      />

      <LinearProgressLoader active={isLoading} />
    </>
  );

  const plan = plans.find((plan) => plan.id === subscription?.productId);

  if (!subscription || !plan) {
    return header;
  }

  const renderAvatar = (member: TUser) => {
    if (!member) {
      return '';
    }
    const avatarName = `${member?.name[0]}${member?.lastname[0]}`;
    return (
      <AvatarBlock>
        {member?.avatar ? (
          <img className="avatar" src={member.avatar} alt="" />
        ) : (
          <div className="avatar-name">{avatarName}</div>
        )}
      </AvatarBlock>
    );
  };

  const tableHead = [
    {
      headComponent: () => <td>ID</td>,
      render: (data: TLicense) => <CellContent>{data.id}</CellContent>,
    },
    {
      headComponent: () => <td>Name</td>,
      render: (data: TLicense) => {
        if (!data.assignee) {
          return <CellContent>Unassigned</CellContent>;
        }
        return (
          <CellContentFlex>
            {renderAvatar(data.assignee)}
            {data.assignee?.name} {data.assignee?.lastname}
          </CellContentFlex>
        );
      },
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Contacts</td>,
      render: (data: TLicense) => {
        if (!data.assignee) {
          return <CellContent></CellContent>;
        }
        return (
          <CellContent>
            <div>{data.assignee.email}</div>
            <div>{data.assignee.phone}</div>
          </CellContent>
        );
      },
    },
    {
      headComponent: () => <td style={{ width: '1%' }}></td>,
      render: (data: TLicense) => {
        if (!data.assignee) {
          return (
            <CellContent>
              <AddIcon className="add" onClick={() => onAddUserClick(data.id)} />
            </CellContent>
          );
        }
        return (
          <CellContent>
            <TrashBin className="delete" onClick={() => removeAssignee(data.id)} />
          </CellContent>
        );
      },
    },
  ];

  return (
    <>
      {header}
      <MainContent>
        <div className="details">
          <div className="left">
            <div className="name">{plan.name}</div>
            <div className="price">${Number(plan.price).toFixed(2)} Per License Monthly</div>
          </div>
          <div className="divider"></div>
          <div className="count">
            You have{' '}
            <span className="bold">
              {unassignedCount} out of {(assignedCount ?? 0) + (unassignedCount ?? 0)}
            </span>{' '}
            licenses available to assign
          </div>
        </div>

        <CustomTable head={tableHead} data={licenses} />
      </MainContent>
    </>
  );
};
