import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { InvitationPage } from 'app/pages/User/InvitationsPage/InvitationPage';
import { Header } from '@components/Header';
import { Button } from '@components/form-elements/buttons/Button';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TInvitation } from 'app/types/entities/TInvitation';
import { useAPI } from '@hooks/useAPI';
import { OrganizationsService } from 'app/API';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';

const MainContent = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;

export const InvitationsPage = () => {
  const { call } = useAPI();
  const navigate = useNavigate();

  const { organization } = useAuthContext();
  const { openModal, closeModal } = useModalContext();

  const [invitations, setInvitations] = useState<TInvitation[]>([]);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // const workspaceViewReports = (workspace?.Teams ?? []).find((team) => team.viewReports);
  // const shouldShowReports = !!workspaceViewReports;
  const shouldShowReports = !!organization?.permissions?.viewReports;

  const fetch = async () => {
    setInvitations([]);
    setIsLoading(true);
    const data = await call(OrganizationsService.getWsInvitations({ oid: organization?.id ?? '', search }));
    setIsLoading(false);
    setInvitations(data.result);
  };

  useEffect(() => {
    fetch();
  }, [organization, reloadPageTrigger, search]);

  const openInviteUserModal = () => {
    openModal(ModalNamesEnum.InviteUserModal, {
      onSuccess: () => {
        closeModal();
        fetch();
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Invitations - VoiceScript</title>
      </Helmet>
      <Header
        leftSideContent={[
          <HeaderSwitchWrapper key="tabSwitcher">
            <Button
              onClick={() => {
                navigate(routes.organizationMembers.make(organization?.slug ?? ''));
              }}
              className="button"
            >
              Members
            </Button>
            <Button
              onClick={() => {
                navigate(routes.invitations.make(organization?.slug ?? ''));
              }}
              className="button active"
            >
              Invitations
            </Button>
            {shouldShowReports ? (
              <Button
                onClick={() => {
                  navigate(routes.organizationMemberReports.make(organization?.slug ?? ''));
                }}
                className="button"
              >
                Reports
              </Button>
            ) : null}
          </HeaderSwitchWrapper>,
        ]}
        title="Invitations"
        setSearch={setSearch}
        search={search}
        buttonLabel={'Invite Member'}
        buttonOnClick={() => openInviteUserModal()}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <InvitationPage invitations={invitations} setReloadPageTrigger={setReloadPageTrigger} />
      </MainContent>
    </>
  );
};
