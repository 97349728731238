import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { GetJobSubscriptionsListDto, GetWorkspaceSubscriptionsListDto, SubscriptionsListDto } from 'app/API';

import { usePageContext } from '../../providers/PageProvider';
import { MainContent } from './styles/MainContent';
import { CheckBox } from '@components/form-elements/CheckBox';
import { CustomTable } from '@components/Table';
import { TrashBin } from '@components/icons/TrashBin';
import { DeleteButton } from '@pages/User/NotificationSettingsPage/components/Content/styles/DeleteButton';
import { LockedJob } from '@components/icons/LockedJob';
import { routes } from '@routes';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthContext } from '@providers/AuthProvider';
import { useNavigate } from 'react-router';
import { DisclosureSection } from '@components/DisclosureSection';
import { useState } from 'react';

const JobNameField = styled.div`
  .job-name {
    color: #00122d;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin: 10px 0 0;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #00122d;
    }
  }

  .id-field {
    margin: 0 0 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 160%;
    color: #b4b9d9;
  }
`;

const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;

export const Content = () => {
  const { me } = useAuthContext();
  const {
    isLoading,
    settings,
    updateSettings,
    subscriptions,
    deleteSubscription,
    jobSubscriptions,
    workspaceSubscriptions,
    unwatchJob,
    unwatchWorkspace,
  } = usePageContext();
  const [jobSubsDisclosureOpen, setJobSubsDisclosureOpen] = useState(false);
  const [workspaceSubsDisclosureOpen, setWorkspaceSubsDisclosureOpen] = useState(false);
  const [devicesDisclosureOpen, setDevicesDisclosureOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSettings = (name: string) => {
    updateSettings({ ...settings, [name]: !settings[name] });
  };

  if (!settings) {
    return <LinearProgressLoader active={isLoading} />;
  }

  const jobClick = (workspaceId: string, id: string) => {
    const workspace = me.workspaces.find((ws) => workspaceId === ws.id);
    if (workspace) {
      const organization = me.organizations.find((org) => workspace.organizationId === org.id);
      if (organization) {
        navigate(routes.jobDetails.make(organization.slug, workspace.slug, id));
      }
    }
  };

  const workspaceClick = (id: string) => {
    const workspace = me.workspaces.find((ws) => id === ws.id);
    if (workspace) {
      const organization = me.organizations.find((org) => workspace.organizationId === org.id);
      if (organization) {
        navigate(routes.workspaceDetails.make(organization?.slug ?? '', workspace?.slug ?? ''));
      }
    }
  };

  const renderDevicesList = () => {
    if (!subscriptions.length) {
      return null;
    }
    return (
      <DisclosureSection
        title="Devices"
        open={devicesDisclosureOpen}
        setOpenState={setDevicesDisclosureOpen}
        loading={false}
      >
        <CustomTable
          className="table"
          noHead={true}
          data={subscriptions}
          head={[
            {
              headComponent: () => <td></td>,
              render: (data: SubscriptionsListDto) => (
                <div key={data.id}>
                  {data.browserName || data.id}
                  {data.browserVersion ? ` v${data.browserVersion}` : ''} (
                  {data.osName || data.deviceName || 'Unknown device'})
                </div>
              ),
            },
            {
              headComponent: () => <td></td>,
              render: (data: SubscriptionsListDto) => (
                <div key={data.id}>
                  <DeleteButton
                    onClick={() => {
                      deleteSubscription(data.id);
                    }}
                    disabled={isLoading}
                  >
                    <TrashBin />
                  </DeleteButton>
                </div>
              ),
            },
          ]}
        />
      </DisclosureSection>
    );
  };

  const renderJobsList = () => {
    if (!jobSubscriptions.length) {
      return null;
    }
    return (
      <DisclosureSection
        title="Job subscriptions"
        open={jobSubsDisclosureOpen}
        setOpenState={setJobSubsDisclosureOpen}
        loading={false}
      >
        <CustomTable
          className="table"
          noHead={true}
          data={jobSubscriptions}
          head={[
            {
              headComponent: () => <td>Job Name</td>,
              render: (data: GetJobSubscriptionsListDto) => {
                const workspace = me.workspaces.find((ws) => data.job.workspaceId === ws.id);
                let organization;
                if (workspace) {
                  organization = me.organizations.find((org) => workspace.organizationId === org.id);
                }
                return (
                  <JobNameField>
                    <div className="flex gap-3 items-center">
                      {data.job.asOneLocked ? <LockedJob className="flex-shrink-0 pl-2" width="30" /> : null}
                      <div>
                        <div className="job-name">
                          {data.job.asOneLocked ? (
                            data.job.name
                          ) : (
                            <div className="flex flex-row">
                              {data.job.meetingId ? <div className="tag mr-2"> AS ONE </div> : null}
                              <StyledLink
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  jobClick(data.job.workspaceId, data.jobId);
                                }}
                                to={routes.jobDetails.make(organization?.slug ?? '', workspace?.slug ?? '', data.id)}
                              >
                                {data.job.name}
                              </StyledLink>
                            </div>
                          )}
                        </div>
                        <div className="id-field">{data.id}</div>
                      </div>
                    </div>
                  </JobNameField>
                );
              },
            },
            {
              headComponent: () => <td></td>,
              render: (data: GetJobSubscriptionsListDto) => (
                <div key={data.id}>
                  <DeleteButton
                    onClick={() => {
                      unwatchJob(data.jobId);
                    }}
                    disabled={isLoading}
                  >
                    <TrashBin />
                  </DeleteButton>
                </div>
              ),
            },
          ]}
        />
      </DisclosureSection>
    );
  };

  const renderWorkspacesList = () => {
    if (!workspaceSubscriptions.length) {
      return null;
    }
    return (
      <DisclosureSection
        title="Workspace subscriptions"
        open={workspaceSubsDisclosureOpen}
        setOpenState={setWorkspaceSubsDisclosureOpen}
        loading={false}
      >
        <CustomTable
          className="table"
          noHead={true}
          data={workspaceSubscriptions}
          head={[
            {
              headComponent: () => <td>Workspace Name</td>,
              render: (data: GetWorkspaceSubscriptionsListDto) => {
                const organization = me.organizations.find((org) => data.workspace.organizationId === org.id);
                return (
                  <div>
                    <div className="flex gap-3 items-center">
                      <StyledLink
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          workspaceClick(data.workspaceId);
                        }}
                        to={routes.workspaceDetails.make(organization?.slug ?? '', data.workspace?.slug ?? '')}
                      >
                        {data.workspace.name} ({data.workspace.organization.name})
                      </StyledLink>
                    </div>
                  </div>
                );
              },
            },
            {
              headComponent: () => <td></td>,
              render: (data: GetWorkspaceSubscriptionsListDto) => (
                <div key={data.id}>
                  <DeleteButton
                    onClick={() => {
                      unwatchWorkspace(data.workspaceId);
                    }}
                    disabled={isLoading}
                  >
                    <TrashBin />
                  </DeleteButton>
                </div>
              ),
            },
          ]}
        />
      </DisclosureSection>
    );
  };

  return (
    <>
      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <CustomTable
          className="table"
          data={[
            { type: 'jobAssigned', name: 'Job Assigned' },
            { type: 'repositoryAssigned', name: 'Repository Assigned' },
            { type: 'workspaceSubscriptions', name: 'Workspace Watch Subscriptions' },
            { type: 'editorSubscriptions', name: 'Editor Subscriptions' },
            { type: 'proofreaderSubscriptions', name: 'Proofreader Subscriptions' },
          ]}
          head={[
            {
              headComponent: () => <td>TYPE</td>,
              render: (data: any) => <p key={data.type}>{data.name}</p>,
            },
            {
              headComponent: () => (
                <td>
                  <div className="items-center flex flex-col justify-center">
                    <div className="label">Email</div>
                    <CheckBox
                      className="checkbox"
                      checked={settings.emailNotifications === undefined ? false : settings.emailNotifications}
                      onChange={() => toggleSettings('emailNotifications')}
                    />
                  </div>
                </td>
              ),
              render: (data: any) => (
                <div className="flex items-center justify-center" key={data.type}>
                  <CheckBox
                    className="switch"
                    disabled={settings.emailNotifications === undefined ? false : !settings.emailNotifications}
                    checked={settings[`${data.type}Email`] === undefined ? false : settings[`${data.type}Email`]}
                    onChange={() => toggleSettings(`${data.type}Email`)}
                  />
                </div>
              ),
            },
            {
              headComponent: () => (
                <td>
                  <div className="items-center flex flex-col justify-center">
                    <div className="label">Push</div>
                    <CheckBox
                      className="checkbox"
                      checked={settings.pushNotifications === undefined ? false : settings.pushNotifications}
                      onChange={() => toggleSettings('pushNotifications')}
                    />
                  </div>
                </td>
              ),
              render: (data: any) => (
                <div className="flex items-center justify-center" key={data.type}>
                  <CheckBox
                    className="switch"
                    disabled={settings.pushNotifications === undefined ? false : !settings.pushNotifications}
                    checked={settings[`${data.type}Push`] === undefined ? false : settings[`${data.type}Push`]}
                    onChange={() => toggleSettings(`${data.type}Push`)}
                  />
                </div>
              ),
            },
          ]}
        />

        <CustomTable
          className="table"
          data={[
            { type: 'jobSubscriptionsStatus', name: 'Status updates' },
            { type: 'jobSubscriptionsAssignee', name: 'Assignee updates' },
            { type: 'jobSubscriptionsComments', name: 'Comments' },
          ]}
          head={[
            {
              headComponent: () => <td>Job Watch Subscriptions</td>,
              render: (data: any) => <p key={data.type}>{data.name}</p>,
            },
            {
              headComponent: () => (
                <td>
                  <div className="items-center flex flex-col justify-center">
                    <div className="label">Email</div>
                    <CheckBox
                      className="checkbox"
                      checked={
                        settings.jobSubscriptionsStatusEmail ||
                        settings.jobSubscriptionsAssigneeEmail ||
                        settings.jobSubscriptionsCommentsEmail
                      }
                      onChange={() => {
                        const value =
                          settings.jobSubscriptionsStatusEmail ||
                          settings.jobSubscriptionsAssigneeEmail ||
                          settings.jobSubscriptionsCommentsEmail;
                        updateSettings({
                          ...settings,
                          jobSubscriptionsStatusEmail: !value,
                          jobSubscriptionsAssigneeEmail: !value,
                          jobSubscriptionsCommentsEmail: !value,
                        });
                      }}
                    />
                  </div>
                </td>
              ),
              render: (data: any) => (
                <div className="flex items-center justify-center" key={data.type}>
                  <CheckBox
                    className="switch"
                    disabled={settings.emailNotifications === undefined ? false : !settings.emailNotifications}
                    checked={settings[`${data.type}Email`] === undefined ? false : settings[`${data.type}Email`]}
                    onChange={() => toggleSettings(`${data.type}Email`)}
                  />
                </div>
              ),
            },
            {
              headComponent: () => (
                <td>
                  <div className="items-center flex flex-col justify-center">
                    <div className="label">Push</div>
                    <CheckBox
                      className="checkbox"
                      checked={
                        settings.jobSubscriptionsStatusPush ||
                        settings.jobSubscriptionsAssigneePush ||
                        settings.jobSubscriptionsCommentsPush
                      }
                      onChange={() => {
                        const value =
                          settings.jobSubscriptionsStatusPush ||
                          settings.jobSubscriptionsAssigneePush ||
                          settings.jobSubscriptionsCommentsPush;
                        updateSettings({
                          ...settings,
                          jobSubscriptionsStatusPush: !value,
                          jobSubscriptionsAssigneePush: !value,
                          jobSubscriptionsCommentsPush: !value,
                        });
                      }}
                    />
                  </div>
                </td>
              ),
              render: (data: any) => (
                <div className="flex items-center justify-center" key={data.type}>
                  <CheckBox
                    className="switch"
                    disabled={settings.pushNotifications === undefined ? false : !settings.pushNotifications}
                    checked={settings[`${data.type}Push`] === undefined ? false : settings[`${data.type}Push`]}
                    onChange={() => toggleSettings(`${data.type}Push`)}
                  />
                </div>
              ),
            },
          ]}
        />

        {renderJobsList()}
        {renderWorkspacesList()}
        {renderDevicesList()}
      </MainContent>
    </>
  );
};
