import { ReactElement } from 'react';

import { Wrapper } from './styles/styles';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import autoscript from './images/autoscript.png';
import one from './images/one.png';

type TProps = {
  children: ReactElement | ReactElement[];
};

export const LoginLayout = ({ children }: TProps) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const productParam = urlSearchParams.get('product');

  if (productParam === 'as1_desktop') {
    return (
      <Wrapper>
        <div className="leftSide">
          {children}
          <div className="leftSideFooter">&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</div>
        </div>

        <div className="rightSide">
          <div className="autoscript-one">
            <img src={one} alt="" />
          </div>
          <div className="convertZoom">Record, annotate, and edit remote and in person proceedings in real-time</div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="leftSide">
        {children}
        <div className="leftSideFooter">&copy; {new Date().getFullYear()} VoiceScript Inc. All Rights Reserved</div>
      </div>

      <div className="rightSide">
        <div className="autoscript">
          <img src={autoscript} alt="" />
        </div>
        <div className="poweredBy">
          Powered by{' '}
          <a href="https://voicescript.ai/" target="_blank" rel="noreferrer" className="link">
            VoiceScript Inc.
          </a>
        </div>
        <div className="convertZoom">
          Convert Zoom, FTR, Reporter Studio PRO, MP3 and other formats into an AI-powered draft transcript
        </div>
      </div>
    </Wrapper>
  );
};
