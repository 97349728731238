import styled from 'styled-components';

export const Wrapper = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  .selected-wrapper {
    display: flex;
    gap: 5px;
    padding: 12px 0 20px;
    align-items: center;
  }
  .selected-tag {
    padding: 4px 6px;
    border-radius: 5px;
    border: 1px solid #d5def2;
    background: #d5def2;
    color: #40608f;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    height: 22px;
    margin: 0 5px 0 0;
  }
`;
