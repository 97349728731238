import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { TJob } from '../../../../../../../types/entities/TJob';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useAPI } from '@hooks/useAPI';

import { JobsService } from 'app/API';
import {
  showClaimButton,
  showMarkAsCompleteButton,
  showMarkAsEditedButton,
  showMarkAsProofreadButton,
} from '@pages/User/RepositoryPage/components/JobsTable/JobStatusActionsButton';

type TProps = {
  job: TJob;
  reload: () => void;
};

export const JobStatusActionsButton = ({ job, reload }: TProps) => {
  const { call } = useAPI();
  const { openModal } = useModalContext();
  const { workspace, organization } = useAuthContext();

  const handleClaimJob = async (job: TJob) => {
    const result = await call(JobsService.claimJob({ wsid: workspace?.id || '', id: job.id }));
    if (result?.code === 10) {
      openModal(ModalNamesEnum.GenericErrorModal, {
        title: 'You’ve reached your job limit',
        message: 'To continue you need to finish jobs you already claimed.',
      });
      return;
    } else if (!result?.success && result?.message) {
      openModal(ModalNamesEnum.GenericErrorModal, {
        message: result.message,
      });
      return;
    }
    reload();
  };

  const handleMarkJobAsComplete = async (jobId: string) => {
    await call(JobsService.markJobAsComplete({ wsid: workspace?.id || '', id: jobId }));
    reload();
  };

  const handleMarkJobAsReadyForProofreading = async (jobId: string) => {
    await call(JobsService.markJobAsReadyForProofreading({ wsid: workspace?.id || '', id: jobId }));
    reload();
  };

  const handleMarkJobAsReadyForFinal = async (jobId: string) => {
    await call(JobsService.markJobAsReadyForFinal({ wsid: workspace?.id || '', id: jobId }));
    reload();
  };

  if (showClaimButton(job, organization)) {
    return (
      <Button onClick={() => handleClaimJob(job)} variant="outlined">
        Claim Job
      </Button>
    );
  }

  if (showMarkAsCompleteButton(job, organization)) {
    return (
      <Button onClick={() => handleMarkJobAsComplete(job.id)} variant="outlined">
        Mark As Complete
      </Button>
    );
  }

  if (showMarkAsEditedButton(job, organization)) {
    return (
      <Button onClick={() => handleMarkJobAsReadyForProofreading(job.id)} variant="outlined">
        Mark as Edited
      </Button>
    );
  }

  if (showMarkAsProofreadButton(job, organization)) {
    return (
      <Button onClick={() => handleMarkJobAsReadyForFinal(job.id)} variant="outlined">
        Mark as Proofread
      </Button>
    );
  }

  return null;
};
