import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';
import { WorkspacesService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { useAuthContext } from '@providers/AuthProvider';
import { TWorkspace } from 'app/types/entities/TWorkspace';

import { Input } from '@components/form-elements/Input';

import { Wrapper } from './styles/Wrapper';
import { SaveButton } from './styles/SaveButton';
import { CheckBox } from '@components/form-elements/CheckBox';

type TProps = {
  isSubmitting: boolean;
  setIsSubmitting: (value: boolean) => void;
  workspace: TWorkspace;
};

export const WorkspaceDetailsForm = ({ isSubmitting, setIsSubmitting, workspace }: TProps) => {
  const { call } = useAPI();
  const { updateMe, organization } = useAuthContext();
  const navigate = useNavigate();
  const canEditWS = organization?.permissions?.manageWorkspaces;

  const [formValues, setFormValues] = useState({
    name: workspace?.name,
    slug: workspace?.slug,
    editorsNotifications: workspace?.editorsNotifications,
    proofreadersNotifications: workspace?.proofreadersNotifications,
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    slug: '',
    editorsNotifications: true,
    proofreadersNotifications: true,
  });
  const [queryError, setQueryError] = useState('');

  useEffect(() => {
    setFormValues({
      name: workspace?.name,
      slug: workspace?.slug,
      editorsNotifications: workspace?.editorsNotifications,
      proofreadersNotifications: workspace?.proofreadersNotifications,
    });
  }, [workspace?.id]);

  const isSlugAvailable = async (value: string) => {
    setIsSubmitting(true);
    // only a-z 0-9 and - are allowed
    const regex = /^[a-z0-9-]+$/;
    if (!value.match(regex)) {
      setFormErrors({ ...formErrors, slug: 'Only a-z, 0-9 and - are allowed' });
      setIsSubmitting(false);
      return;
    }

    const data = await call(
      WorkspacesService.isSlugAvailable({
        oid: organization?.id ?? '',
        wsSlug: value,
      }),
    );
    data.available
      ? setFormErrors({ ...formErrors, slug: '' })
      : setFormErrors({ ...formErrors, slug: 'This URL is already in use' });
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const data = await call(
      WorkspacesService.updateWorkspace({
        wsid: workspace?.id ?? '',
        requestBody: {
          name: formValues.name || '',
          slug: formValues.slug || '',
          editorsNotifications: formValues.editorsNotifications,
          proofreadersNotifications: formValues.proofreadersNotifications,
        },
      }),
    );
    if (data.success) {
      await updateMe();
      navigate(routes.workspaceDetails.make(organization?.slug, formValues.slug));
      setIsSubmitting(false);
      return;
    }
    if (data.error) {
      setQueryError(data.error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Wrapper>
        <div className="leftSide">
          <h3>General</h3>
        </div>

        <div className="rightSide">
          <div className="rightSide-columns">
            <div className="rightSide-first-row">
              <div className="input-wrapper">
                <label htmlFor="name">Workspace name</label>
                <Input
                  className="whiteInput"
                  disabled={!canEditWS || isSubmitting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormValues({
                      ...formValues,
                      name: e.target.value,
                    })
                  }
                  name="name"
                  value={formValues.name}
                  label="Workspace name"
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="slug">Workspace URL</label>
                <Input
                  className="whiteInput"
                  disabled={!canEditWS}
                  name="slug"
                  value={formValues.slug}
                  label="Workspace URL"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormValues({ ...formValues, slug: e.target.value });
                    isSlugAvailable(e.target.value);
                  }}
                  error={!!formErrors.slug}
                />
              </div>
            </div>
          </div>

          <div className="messages-row-wrapper">
            {queryError ? <div className="error-message">{queryError}</div> : null}
            {formErrors.name ? <div className="error-message">{formErrors.name}</div> : null}
            {formErrors.slug ? <div className="error-message">{formErrors.slug}</div> : null}
            {workspace?.slug !== formValues.slug ? (
              <div className="info-message">This change will invalidate sent out job sharing links</div>
            ) : null}
          </div>

          {canEditWS ? (
            <div className="checkbox-wrapper">
              <CheckBox
                disabled={!canEditWS}
                onChange={() => {
                  setFormValues({ ...formValues, editorsNotifications: !formValues.editorsNotifications });
                }}
                checked={formValues.editorsNotifications}
              />
              <label htmlFor="name">Enable notifications for Editors</label>
            </div>
          ) : null}

          {canEditWS ? (
            <div className="checkbox-wrapper">
              <CheckBox
                disabled={!canEditWS}
                onChange={() => {
                  setFormValues({ ...formValues, proofreadersNotifications: !formValues.proofreadersNotifications });
                }}
                checked={formValues.proofreadersNotifications}
              />
              <label htmlFor="name">Enable notifications for Proofreaders</label>
            </div>
          ) : null}

          {canEditWS ? (
            <div className="save-wrapper">
              <SaveButton
                variant="bold"
                disabled={
                  !canEditWS ||
                  isSubmitting ||
                  !!(formErrors.slug || formErrors.name) ||
                  !(formValues.slug && formValues.name)
                }
                onClick={() => handleSubmit()}
              >
                Save
              </SaveButton>
            </div>
          ) : null}
        </div>
      </Wrapper>
    </>
  );
};
