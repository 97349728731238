import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { routes } from '@routes';
import { SsoProviderService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

import { Error } from '@components/Error';
import { Input } from '@components/form-elements/Input';
import { Spinner } from '@components/spinners/Spinner';
import { EmailIcon } from '@components/icons/Email';
import { EyeIcon } from '@components/icons/Eye';
import { EyeClosedIcon } from '@components/icons/EyeClosed';

import { Wrapper } from './styles';
import { LoginButton } from './components/LoginButton';
import { usePageContext } from '../../providers/PageProvider';

enum LoginStep {
  CHECK_DOMAIN = 'CHECK_DOMAIN',
  SSO_LOGIN = 'SSO_LOGIN',
  PASSWORD_LOGIN = 'PASSWORD_LOGIN',
}

const determineLoginStep = (emailDomainChecked: boolean, ssoRedirectUrl: string | null) => {
  if (!emailDomainChecked) {
    return LoginStep.CHECK_DOMAIN;
  }

  if (ssoRedirectUrl) {
    return LoginStep.SSO_LOGIN;
  }

  return LoginStep.PASSWORD_LOGIN;
};

export const LoginPageContent = () => {
  const { call } = useAPI();
  const { isLoading, email, setEmail, password, setPassword, error, login } = usePageContext();
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [emailDomainChecked, setEmailDomainChecked] = useState(false);
  const [ssoRedirectUrl, setSsoRedirectUrl] = useState<string | null>(null);

  const emailParam = urlSearchParams.get('email');
  if (emailParam) {
    setEmail(emailParam);
  }

  const loginStep = determineLoginStep(emailDomainChecked, ssoRedirectUrl);

  const handleEmailDomainChecking = async () => {
    const emailDomain = email?.split('@')[1];

    const result = await call(SsoProviderService.checkDomain({ requestBody: { domain: emailDomain } }));

    if (result?.ssoRedirectUrl) {
      setSsoRedirectUrl(result.ssoRedirectUrl);
    }

    setEmailDomainChecked(true);
  };

  const shouldDisableButton = () => {
    if (loginStep === LoginStep.CHECK_DOMAIN) {
      return !email;
    }

    if (loginStep === LoginStep.SSO_LOGIN) {
      return !ssoRedirectUrl;
    }

    return !email || !password;
  };

  const goToRegistration = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    const goto = urlSearchParams.get('goto');
    const email = urlSearchParams.get('email');
    if (goto) {
      navigate(`${routes.signup.make()}?goto=${goto}&email=${encodeURIComponent(email ?? '')}`);
      return;
    }
    navigate(routes.signup.make());
  };

  const handleRecovery = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    navigate(routes.passwordRecovery.make());
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      {isLoading && <Spinner overlay={true} />}
      <Wrapper>
        <div className="content">
          <div>
            <h1 className="welcomeHeader">Welcome!</h1>
            <h2 className="signInToYourAccount">Sign In to Your Account</h2>
          </div>
          <div className="form">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <form onSubmit={login}>
              <div className="margin30">
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  handleBlur={handleEmailDomainChecking}
                  name="email"
                  label="Email"
                  type="text"
                  value={email || ''}
                  required={true}
                  icon={EmailIcon}
                  disabled={!!emailParam}
                />
              </div>
              {loginStep === LoginStep.PASSWORD_LOGIN ? (
                <div className="margin30">
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    onIconClick={togglePasswordVisibility}
                    name="password"
                    label="Password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    value={password || ''}
                    required={true}
                    icon={isPasswordVisible ? EyeIcon : EyeClosedIcon}
                  />
                </div>
              ) : null}
              <a href="#" className="forgotPassword" tabIndex={-1} onClick={handleRecovery}>
                Forgot password?
              </a>

              {error ? (
                <div className="formError" data-testid="errorMessage">
                  <Error error={error} />
                </div>
              ) : null}

              <LoginButton
                disabled={shouldDisableButton() || isLoading}
                loginStep={loginStep}
                checkEmailDomain={handleEmailDomainChecking}
                emailDomainChecked={emailDomainChecked}
                ssoRedirectUrl={ssoRedirectUrl}
              />

              <p className="dontHaveAnAccount">
                {"Don't have an account yet? "}&nbsp;&nbsp;
                <span onClick={goToRegistration} className="signUp">
                  Sign Up
                </span>
              </p>
            </form>
          </div>
        </div>
      </Wrapper>
    </>
  );
};
