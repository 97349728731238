import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  .leftSide {
    width: 230px;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #878787;
    flex: 0 0 auto;

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #858dbd;
      padding: 0 0 10px;
    }
  }

  .rightSide {
    width: 100%;

    .rightSide-columns {
      display: flex;
      gap: 10px;

      .rightSide-first-row {
        display: flex;
        width: 100%;
        gap: 20px;
        padding: 0 0 5px;
      }
    }
  }

  .input-wrapper {
    position: relative;
    width: 280px;
    flex: 0 0 auto;

    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #858dbd;
    }

    .whiteInput {
      padding: 4px 0 0;

      input {
        height: 30px;
        background: white !important;
      }
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    color: #858dbd;
    margin: 10px 0;
  }

  .messages-row-wrapper {
    width: 100%;
    .error-message {
      color: #ff2f2f;
      font-weight: 500;
      text-align: center;
      line-height: 100%;
      padding: 0 0 20px;
    }
    .info-message {
      color: #858dbd;
      text-align: left;
      padding: 0 0 20px;
    }
  }

  .save-wrapper {
    display: flex;
    justify-content: end;
    padding: 0 0 20px;
  }
`;
