import { SVGProps } from 'react';

export const Briefcase = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56151 4.76152C9.04911 4.27393 9.71042 4 10.4 4H13.6C14.2895 4 14.9509 4.27393 15.4385 4.76152C15.9261 5.24912 16.2 5.91044 16.2 6.6V19.4C16.2 19.9523 15.7523 20.4 15.2 20.4C14.6477 20.4 14.2 19.9523 14.2 19.4V6.6C14.2 6.44087 14.1368 6.28826 14.0243 6.17574C13.9117 6.06321 13.7591 6 13.6 6H10.4C10.2409 6 10.0882 6.06321 9.97572 6.17574C9.8632 6.28826 9.79999 6.44087 9.79999 6.6V19.4C9.79999 19.9523 9.35227 20.4 8.79999 20.4C8.2477 20.4 7.79999 19.9523 7.79999 19.4V6.6C7.79999 5.91044 8.07392 5.24912 8.56151 4.76152Z"
      fill="#858DBD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6 9.19995C5.26863 9.19995 5 9.46858 5 9.79995V17.8C5 18.1313 5.26863 18.4 5.6 18.4H18.4C18.7314 18.4 19 18.1313 19 17.8V9.79995C19 9.46858 18.7314 9.19995 18.4 9.19995H5.6ZM3 9.79995C3 8.36401 4.16406 7.19995 5.6 7.19995H18.4C19.8359 7.19995 21 8.36401 21 9.79995V17.8C21 19.2359 19.8359 20.4 18.4 20.4H5.6C4.16406 20.4 3 19.2359 3 17.8V9.79995Z"
      fill="#858DBD"
    />
  </svg>
);
