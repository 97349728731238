import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px 30px 0;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  position: relative;

  .head-buttons {
    display: flex;
    justify-content: end;
    gap: 15px;
  }
`;
